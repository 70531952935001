.notification {
    border-radius: 2px;
    box-shadow:
        0 1px 15px rgba(0, 0, 0, 0.04),
        0 1px 6px rgba(0, 0, 0, 0.04);
    padding: 25px 25px 25px 25px;
}

.notification:before {
    content: "";
    display: none;
}

.notification-primary {
    color: #8b26b2ff;
    background-color: #ffffffff;
    border: 1px solid #8b26b2ff;
    opacity: 1;

    .notification-message {
        .title {
            color: #8b26b2ff;
        }

        .message {
            color: #3a3a3a;
        }
    }

    &.filled {
        color: #ffffffff;
        background-color: #8b26b2ff;

        .notification-message {
            .title {
                color: #ffffffff;
            }

            .message {
                color: #ffffffff;
            }
        }
    }
}

.notification-secondary {
    color: #4556ac;
    background-color: #ffffffff;
    border: 1px solid #4556ac;
    opacity: 1;

    .notification-message {
        .title {
            color: #4556ac;
        }

        .message {
            color: #8f8f8f;
        }
    }

    &.filled {
        color: #ffffffff;
        background-color: #4556ac;

        .notification-message {
            .title {
                color: #ffffffff;
            }

            .message {
                color: #ffffffff;
            }
        }
    }
}

.notification-info {
    color: #3195a5;
    background-color: #ffffffff;
    border: 1px solid #3195a5;
    opacity: 1;

    .notification-message {
        .title {
            color: #3195a5;
        }

        .message {
            color: #8f8f8f;
        }
    }

    &.filled {
        color: #ffffffff;
        background-color: #3195a5;

        .notification-message {
            .title {
                color: #ffffffff;
            }

            .message {
                color: #ffffffff;
            }
        }
    }
}

.notification-success {
    color: #3e884f;
    background-color: #ffffffff;
    border: 1px solid #3e884f;
    opacity: 1;

    .notification-message {
        .title {
            color: #3e884f;
        }

        .message {
            color: #8f8f8f;
        }
    }

    &.filled {
        color: #ffffffff;
        background-color: #3e884f;

        .notification-message {
            .title {
                color: #ffffffff;
            }

            .message {
                color: #ffffffff;
            }
        }
    }
}

.notification-warning {
    color: #b69329;
    background-color: #ffffffff;
    border: 1px solid #b69329;
    opacity: 1;

    .notification-message {
        .title {
            color: #b69329;
        }

        .message {
            color: #8f8f8f;
        }
    }

    &.filled {
        color: #ffffffff;
        background-color: #b69329;

        .notification-message {
            .title {
                color: #ffffffff;
            }

            .message {
                color: #ffffffff;
            }
        }
    }
}

.notification-error {
    color: #c43d4b;
    background-color: #ffffffff;
    border: 1px solid #c43d4b;
    opacity: 1;

    .notification-message {
        .title {
            color: #c43d4b;
        }

        .message {
            color: #8f8f8f;
        }
    }

    &.filled {
        color: #ffffffff;
        background-color: #c43d4b;

        .notification-message {
            .title {
                color: #ffffffff;
            }

            .message {
                color: #ffffffff;
            }
        }
    }
}

.notification-container {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    width: 320px;
    padding: 0 15px;
    max-height: calc(100% - 30px);
    overflow-x: hidden;
    overflow-y: auto;
}

.notification {
    box-sizing: border-box;
    padding: 15px 15px 15px 58px;
    border-radius: 2px;
    cursor: pointer;
    font-size: 1em;
    line-height: 1.2em;
    position: relative;
    opacity: 0.9;
    margin-top: 15px;
}

.notification .title {
    font-size: 1em;
    line-height: 1.2em;
    font-weight: 700;
    margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
    opacity: 1;
}

.notification-enter {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: all 0.4s;
}

.notification-leave {
    visibility: visible;
    transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
    transition: all 0.4s;
}

.rounded {
    .notification {
        border-radius: 2px;
    }
}
